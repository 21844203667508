import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '@app/shared';
import { Contact } from '@app/modules/contact/contact.model';


@Injectable()
export class ProgramService {

  constructor(
    private apiService: ApiService
  ) { }

  /** Get all embellishment details*/
  getEmbroidery(url, params): Observable<any> {
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get all embellishment details*/
  getData(url, params): Observable<any> {
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Save customer contacts */
  saveCustomerContacts(url, contact: Contact): Observable<Contact> {
    return this.apiService.post(url, contact).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

  /** Get all portfolio details*/
  getPortfolio(url, params): Observable<any> {
    return this.apiService.get(url, params).pipe(
      map(
        data => {
          return data;
        }
      ));
  }

}
