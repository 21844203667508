import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  config: any;
  fullpage_api: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) {
    this.config = {
      navigation: true,
      licenseKey: 'F46DDBA3-E2ED4D68-981299F4-0394CD61',
      afterResize: () => {
      },
      afterLoad: (origin, destination, direction) => {
      }
    };
  }

  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'home');
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'home');
  }

}
