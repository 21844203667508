import { OnInit, Component, ViewContainerRef, AfterViewInit } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import { AuthService } from '@app/shared/index';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit , AfterViewInit {

  constructor (
    public vcr: ViewContainerRef,
    private authService: AuthService,
    private router: Router
  ) { }
  ngAfterViewInit(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => { 
        window.scrollTo(0,0)
      })
  }
  ngOnInit() {
    // this.authService.populate();
  }
}
