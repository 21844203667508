import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'app/shared/shared.module';

// custom component imports
import {
  ProductAccordionComponent,
  ProgramViewComponent,
  ImageLoaderComponent
} from './components';
import { ProgramService } from './services/program.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [
    ProductAccordionComponent,
    ProgramViewComponent,
    ImageLoaderComponent,
  ],
  exports: [
    ProductAccordionComponent,
    ProgramViewComponent,
    ImageLoaderComponent,
    SharedModule
  ],
  providers: [
    ProgramService
  ],
})
export class ContentSharedModule { }
