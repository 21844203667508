
import {timer as observableTimer, interval as observableInterval, of as observableOf,  Observable ,  BehaviorSubject ,  ReplaySubject } from 'rxjs';

import {distinctUntilChanged, map, mergeMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';

import { ApiService } from './api.service';
import { StorageService } from './storage.service';
import { User } from '../models';

@Injectable()
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>(new User());
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();
  public refreshSubscription: any;
  redirectUrl: string;
  cachedRequests: Array<HttpRequest<any>> = [];

  constructor (
    private apiService: ApiService,
    private router: Router,
    private storage: StorageService,
  ) {}

  getToken(): String {
    return this.storage.retrieve('authToken');
  }

  saveToken(token: String) {
    this.storage.store('authToken', token);
  }

  destroyToken() {
    this.storage.clear('authToken');
  }

  isTokenExpired(token: String, offsetSeconds?: number): boolean {
    const expiresAt = parseInt(this.storage.retrieve('exp_date'), 10);
    return Date.now() > expiresAt;
  }

  collectFailedRequest(request): void {
    this.cachedRequests.push(request);
    console.log(this.cachedRequests)
  }

  retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }

  // Verify JWT in localstorage with server & load user's info.
  // This runs once on application startup.
  populate() {
    // If JWT detected, attempt to get & store user's info
    if (this.getToken()) {
      const token = this.getToken();
      const tokenExpired = this.isTokenExpired(token);
      if (tokenExpired) {
        // Remove any potential remnants of previous auth states
        this.purgeAuth();
      } else {
        const temp = {'access_token' : this.getToken()};
        this.setAuth(temp);
        this.startupTokenRefresh();
      }
    } else {
      // Remove any potential remnants of previous auth states
      this.purgeAuth();
    }
  }

  setAuth(data: any) {
    // Save JWT sent from server in localstorage
    this.saveToken(data.access_token);
    // Set isAuthenticated to true
    this.isAuthenticatedSubject.next(true);

    this.getUserInfo();
    this.setNavigation()
    this.getCountryStateList();
    this.getOrderOptions();
    this.getSuppliers();
    this.getCrmOptions();
    // this.getUserAcl();
    this.getPriceGroups();
    this.getVendors();
    this.getOfficeList();
    this.getStoreList();
    this.getCustomerAccessGroups();
  }

  getUserInfo() {
    if (!this.storage.retrieve('user')) {
      this.apiService.get('/auth-user')
      .subscribe(response => {
        if (!response['settings']) {
          response['settings'] = {};
        }
        this.storage.store('user', response)
        // Set current user data into observable
        this.currentUserSubject.next(response);
        this.getEmployeeDetails(response.id);
      })
    } else {
      const user = this.storage.retrieve('user');
      this.currentUserSubject.next(user);
    }
  }

  getEmployeeDetails(id) {
    this.apiService.get(`/employees/${id}`).subscribe(response => {
      const user = this.storage.retrieve('user');
      user['settings'] = response['user']['settings'];
      if (response['employee_profile']) {
        user['hire_date'] = response['employee_profile']['hire_date'];
        user['department'] = response['employee_profile']['department'];
      }
      this.storage.store('user', user);
    })
  }

  purgeAuth() {
    // Remove JWT from localstorage
    this.revokeTokenNoResponse();
    // this.destroyToken();
    this.storage.clearAll();
    // Set auth status to false
    this.isAuthenticatedSubject.next(false);
    // Unschedule the token refresh
    this.unscheduleRefresh();
  }

  attemptAuth(type, credentials): Observable<any> {
    const creds = 'grant_type=password&password=' + encodeURIComponent(credentials['password']) + '&username=' + encodeURIComponent(credentials['username']);
    return this.apiService.authPost('/token/', creds).pipe(
    map(
      data => {
        this.setAuth(data);
        const expiresAt: any = data.expires_in * 1000 + new Date().getTime();

        this.storage.store('exp_delay', data.expires_in.toString());
        this.storage.store('exp_date', expiresAt);
        this.storage.store('refresh_token', data.refresh_token);

        this.scheduleRefresh();
        return data;
      }
    ));
  }

  getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  // Update the user on the server (email, pass, etc)
  update(user): Observable<User> {
    return this.apiService
    .put('/user', { user }).pipe(
    map(data => {
      // Update the currentUser observable
      this.currentUserSubject.next(data.user);
      return data.user;
    }));
  }

  /** Get navigation data   */
  getNavigation(): Observable<any> {
    const url = `/navigations`
    return this.apiService.get(url).pipe(
      map(
        data => { return data; }
      ));
  }

  setNavigation() {
    if (!this.storage.retrieve('navigation', 'session')) {
      this.apiService.get('/navigations').subscribe(response => {
        this.buildNavigation(response['top']);
      })
    } else {
      const navigations = this.storage.retrieve('navigation', 'session');
      this.buildNavigation(navigations);
    }
  }

  buildNavigation (navigations) {
    const leftNavigation = {};
    navigations.forEach((topNav) => {
      leftNavigation[topNav.slug] = [];
      if (topNav['children']['left']) {
        topNav['children']['left'].forEach((childNav) => {
          leftNavigation[topNav.slug].push(childNav);
        });
      }
    });
    this.storage.store('navigation', navigations, 'session');
    this.storage.store('leftNavigation', leftNavigation, 'session');
  }

  scheduleRefresh() {
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const idToken = this.getToken();
    const source = observableOf(idToken).pipe(mergeMap(
      token => {
        // tslint:disable-next-line:radix
        let delay = parseInt(this.storage.retrieve('exp_delay'));
        const refreshTokenThreshold = 10;
        delay = (delay - refreshTokenThreshold) * 1000;
        // tslint:disable-next-line:radix
        return observableInterval(delay);
      }));

    this.refreshSubscription = source.subscribe(() => {
      this.getNewJwt().subscribe(
        (res) =>  {},
        (error) => {
          this.purgeAuth();
          console.log('-> Refresh error:' + JSON.stringify(error));
          this.router.navigateByUrl('/login');
        }
      );
    });
  }

  startupTokenRefresh() {
    console.log('startupTokenRefresh')
    // If the user is authenticated, use the token stream
    // provided by angular2-jwt and flatMap the token
    const idToken = this.getToken();
    const tokenExpired = idToken ? this.isTokenExpired(idToken) : true;
    if (!tokenExpired) {
      const source = observableOf(idToken).pipe(mergeMap(
        token => {
        // Get the expiry time to generate

        const now: any = new Date().valueOf();

        // tslint:disable-next-line:radix
        const date: any =  this.storage.retrieve('exp_date') ? parseInt(this.storage.retrieve('exp_date')) : 0;
        const temp = new Date(date).getTime();
        const refreshTokenThreshold = 10; // seconds

        // a delay in milliseconds
        let delay: any = temp - now;
        (delay < refreshTokenThreshold ) ? delay = 1 : delay = delay - refreshTokenThreshold;

        // Use the delay in a timer to
        // run the refresh at the proper time
        return observableTimer(delay);
      }));

       // Once the delay time from above is
       // reached, get a new JWT and schedule
       // additional refreshes
       source.subscribe(() => {
        this.getNewJwt().subscribe(
          (res) => {
          this.scheduleRefresh();
          },
          (error) => {
            this.purgeAuth();
            console.log('-> Refresh error:' + JSON.stringify(error));
            this.router.navigateByUrl('/login');
          }
        )
       });
    }
  }

  unscheduleRefresh() {
    // Unsubscribe from the refresh
    if (this.refreshSubscription) {
      this.refreshSubscription.unsubscribe();
    }
  }

  getNewJwt() {
    const refreshToken = this.storage.retrieve('refresh_token')
    const creds = 'grant_type=refresh_token&refresh_token=' + encodeURIComponent(refreshToken);

    return this.apiService.authPost('/token/', creds).pipe(
      map(
        data => {
          this.setAuth(data);
          this.storage.store('exp_delay', data.expires_in.toString())
          const expiresAt = (data.expires_in * 1000) + new Date().getTime();
          this.storage.store('exp_date', expiresAt)
          this.storage.store('refresh_token', data.refresh_token)
        }
      ));
  }

  getCountryStateList() {
    if (!this.storage.retrieve('countryStateList')) {
      this.apiService.get('/country_state_list')
      .subscribe(response => {
        this.storage.store('countryStateList', response);
      });
    }
  }

  getOrderOptions() {
    if (!this.storage.retrieve('orderOptions')) {
      this.apiService.get('/order_option_list')
      .subscribe(response => {
        this.storage.store('orderOptions', response);
      });
    }
  }

  getCrmOptions() {
    if (!this.storage.retrieve('crmOptions')) {
      this.apiService.get('/crm/settings')
      .subscribe(response => {
        this.storage.store('crmOptions', response);
      });
    }
  }

  getAuthUserDetails() {
    if (this.storage.retrieve('user')) {
      return this.storage.retrieve('user');
    }
    return false;
  }

  /** Get all suppliers*/
  getSuppliers() {
    if (!this.storage.retrieve('suppliers', 'session')) {
      this.apiService.get('/suppliers')
      .subscribe(response => {
        this.storage.store('suppliers', response.results, 'session');
      });
    }
  }

  /** Get user acl*/
  getUserAcl() {
    if (!this.storage.retrieve('acl', 'session')) {
      this.apiService.get('/users/acl')
      .subscribe(response => {
        this.storage.store('acl', response, 'session');
      });
    }
  }

  /** Get all Price Groups*/
  getPriceGroups() {
    if (!this.storage.retrieve('priceGroups', 'session')) {
      this.apiService.get('/price_group?all')
      .subscribe(response => {
        this.storage.store('priceGroups', response, 'session');
      });
    }
  }

  /** Get all Vendors*/
  getVendors() {
    if (!this.storage.retrieve('vendors', 'session')) {
      this.apiService.get('/vendors/?all')
      .subscribe(response => {
        this.storage.store('vendors', response, 'session');
      });
    }
  }

  /** Get office list */
  getOfficeList() {
    if (!this.storage.retrieve('officeList', 'session')) {
      const url = `/office?all=true`;
      this.apiService.get(url).subscribe(response => {
        this.storage.store('officeList', response, 'session');
      });
    }
  }

  revokeTokenNoResponse() {
    if (this.storage.retrieve('authtoken')) {
      const token = this.storage.retrieve('authtoken')
      const creds = 'token=' + encodeURIComponent(token);
      this.apiService.authPost('/revoke_token/', creds)
      .subscribe(response => {})
    }
  }

  revokeToken() {
    const token = this.storage.retrieve('authtoken')
    const creds = 'token=' + encodeURIComponent(token);

    return this.apiService.authPost('/revoke_token/', creds)
    .pipe(
      map(response => {
        return response;
      }));
  }

  /** Get store list */
  getStoreList() {
    if (!this.storage.retrieve('stores')) {
      const url = `/stores?view=all`;
      this.apiService.get(url).subscribe(response => {
        if (response.length > 0) {
          this.storage.store('stores', response);
          this.storage.store('current_store_id',  response[0].id, 'session');
        }
      });
    }
  }

  /** Get navigation data   */
  getNotifications(params: HttpParams): Observable<any> {
    const url = `/notifications/`
    return this.apiService.get(url, params).pipe(
      map(
        data => { return data; }
      ));
  }

  updateNotifications(type: String) {
    let url = `/notifications/mark_as_read`;
    if (type === 'viewed') {
      url = `/notifications/mark_as_viewed`;
    }
    return this.apiService
      .patch(url).pipe(
      map(data => {
        return data;
      }));
  }

  getCustomerAccessGroups() {
    if (!this.storage.retrieve('customerAccessGroups', 'session')) {
      this.apiService.get('/customer_groups').subscribe(response => {
        this.storage.store('customerAccessGroups', response, 'session');
      });
    }
  }
}
