import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import * as $ from 'jquery';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  menuItem: any = [];
  @ViewChild('navtoggler') navtoggler;
  @ViewChild('navCollapse') navCollapse;

  constructor(public router: Router) { }

  ngOnInit() {
    this.menuItem = [
      {
        'title': 'PROGRAMS',
        'slug': 'programs',
        'header': 'hat programs.',
        'submenu': [
          {'title': 'blank stock', 'slug': 'blank-stock-program/styles'},
          {'title': 'made in USA', 'slug': 'made-in-usa-program/styles'},
          {'title': 'import', 'slug': 'import-programs/shapes'},
        ]
      },
      { 'title': 'COMPARE PROGRAMS', 'slug': 'compare-programs' },
      { 'title': 'PORTFOLIO', 'slug': 'portfolio' },
      { 'title': 'ABOUT', 'slug': 'about' },
      { 'title': 'CONTACT', 'slug': 'contact' }
    ];
    
    $(function() {
      $('.dropdown').hover(function() {
        if(window.innerWidth > 768) {
          $('.dropdown-menu').addClass('show');
        }
      },
      function() {
        if(window.innerWidth > 768) {
          $('.dropdown-menu').removeClass('show');
        }
      });
      $('.dropdown').click(function() {
        if(window.innerWidth < 768) {
          $('.dropdown-menu').addClass('show');
        }
      },
      function() {
        if(window.innerWidth < 768) {
          $('.dropdown-menu').removeClass('show');
        }
      });
    });
  }
  @HostListener('window:click', ['$event'])
  checkLink() {
    // tslint:disable-next-line: deprecation
    if (!(<Element>event.target).classList.contains('navbar-toggler-icon') && window.innerWidth < 768) {
      if (this.navCollapse.nativeElement.classList.contains('show')) {
        this.navtoggler.nativeElement.click();
      }
    }
  }
  removeMenu() {
    $('.dropdown-menu').removeClass('show');
  }
}



