import { Component, OnInit, Input, HostListener } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-program-view',
  templateUrl: './program-view.component.html',
  styleUrls: ['./program-view.component.scss']
})
export class ProgramViewComponent implements OnInit {
  @Input() productDetails: any;
  @Input() tabNavigation: any;
  @Input() filters: any;
  tabActive: string;
  width: any;
  // isSticky = false;

  constructor(private route: ActivatedRoute, private router: Router) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.tabActive = sessionStorage.getItem('tabActive');
      }
    });
  }

  ngOnInit() {
    sessionStorage.setItem('productDetails', JSON.stringify(this.productDetails));
    sessionStorage.setItem('filters', JSON.stringify(this.filters));
    this.width = (100 / this.tabNavigation.length) + '%';
    // $(document).ready(function()
    // {
		//    $('a').hover(
		//        function(){ $(this).addClass('hover-color') },
		//         function(){ $(this).removeClass('hover-color') }
		//     )
    // });
  }

  // @HostListener('window:scroll', ['$event'])
  // checkScroll() {
  //   this.isSticky = window.pageYOffset >= 140;
  // }

  tabClick(e) {
    sessionStorage.setItem('tabActive', e);
    this.tabActive = sessionStorage.getItem('tabActive');
  }
  
}
