import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Params } from '@angular/router';
import { ToasterService } from '@app/shared';
import { ProgramService } from '@app/modules/shared/services/program.service';
import { HttpParams } from '@angular/common/http';
import { ToastrComponentlessModule } from 'ngx-toastr';
declare var AOS: any;
@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss']
})
export class PortfolioComponent implements OnInit {
  productData: any[];
  tabActive: any;
  show = true;
  productId: any;
  params: HttpParams;
  loading: boolean;
  result: any;
  resutItems = [];
  pricing: any;
  apiUrl = 'portfolio/';
  imgLoaded: boolean;
  viewCount = 2;
  imageSingleDataA: any;
  imageSingleDataB: any;
  filterVisible = false;
  filters: any = [];
  paramskey = {};
  dataKey: any[];
  filterCount: any;
  filterArray: any;
  filterItems = [];
  count: any;
  routeCount = 0;
  buttonData : any;
  viewFabric: boolean;
  prevIndex = 0;
  filterVisibleBeanies: boolean;
  filterVisibleHats: boolean;
  imageAllData: any;
  offsetValue: any = 0;

  constructor(private route: ActivatedRoute, private toastr: ToasterService, private programService: ProgramService,  private router: Router) {
    this.tabActive = this.route.snapshot.data['id'];
    sessionStorage.setItem('tabActive', this.tabActive);
  }

  ngOnInit() {
    this.productData = [
      { 'title': 'SPECIFICATIONS' },
      { 'title': 'COLORS' },
      { 'title': 'PRICING' },
      { 'title': 'DOWNLOADS' },
    ];

    this.filters = {
      'hats': [
        {
          'title': 'Program',
          'slug': 'program',
          'data': [
            { 'title': 'Blank Stock', 'slug': 'blank' },
            { 'title': 'Made in USA', 'slug': 'MadeinUSA' },
            { 'title': 'Import', 'slug': 'import' }
          ]
        },
        {
          'title': 'STRUCTURE',
          'slug': 'structure',
          'data': [
            { 'title': 'Structured', 'slug': 'Structured Hat' },
            { 'title': 'Unstructured', 'slug': 'Unstructured Hat' }
          ]
        },
        {
          'title': 'Brim',
          'slug': 'brim',
          'data': [
            { 'title': 'Flat Visor', 'slug': 'Flat' },
            { 'title': 'Curved Visor', 'slug': 'Curved' },
            { 'title': 'Misc Visor', 'slug': 'Misc' }
          ]
        },
        {
          'title': 'CLOSURE',
          'slug': 'closure',
          'data': [
            { 'title': 'Snapback', 'slug': 'Snapback' },
            { 'title': 'Leather Strap', 'slug': 'Leather Strap' },
            { 'title': 'Self Fabric Strap', 'slug': 'Self Fabric Strap' },
            { 'title': 'Velcro', 'slug': 'Velcro' },
            { 'title': 'Zipper Strap', 'slug': 'Zipper Strap' },
            { 'title': 'Nylon Strap', 'slug': 'Nylon Strap' },
            { 'title': 'Bungee', 'slug': 'Bungee' },
            { 'title': 'Closed Back', 'slug': 'Closed Back' },
            { 'title': 'Stretch Back', 'slug': 'Stretch Back' },
          ]
        },
        {
          'title': 'STYLE',
          'slug': 'style',
          'data': [
            { 'title': 'Snapback', 'slug': 'Snapback' },
            { 'title': 'Strapback', 'slug': 'Strapback' },
            { 'title': 'Dad Hat', 'slug': 'Dad Hat' },
            { 'title': 'Trucker Hat', 'slug': 'Trucker Hat' },
            { 'title': 'Unstructured Hat', 'slug': 'Unstructured Hat' },
            { 'title': 'Camp Cap', 'slug': 'Camp Cap' },
            { 'title': 'Hybrid Hat', 'slug': 'Hybrid Hat' },
            { 'title': 'Curved Brim Hat', 'slug': 'Curved Brim Hat' },
            { 'title': 'Curved Brim Trucker', 'slug': 'Curved Brim Trucker' },
            { 'title': 'Bucket Hat', 'slug': 'Bucket Hat' },
            { 'title': 'Runner Hat', 'slug': 'Runner Hat' },
            { 'title': 'Outdoor Hat', 'slug': 'Outdoor Hat' },
            { 'title': 'FlexFit Style', 'slug': 'FlexFit Style' }
          ]
        }
      ],
      'beanies': [
        {
          'title': 'Program',
          'slug': 'program',
          'data': [
            { 'title': 'Blank Stock', 'slug': 'blank' },
            { 'title': 'Import', 'slug': 'import' }
          ]
        },
        {
          'title': 'Knit Style',
          'slug': 'knit_style',
          'data': [
            { 'title': 'Ribbed', 'slug': 'Ribbed Beanie' },
            { 'title': 'Fine Guage', 'slug': 'Fine Gauge Beanie' },
            { 'title': 'Waffle', 'slug': 'Waffle Beanie' },
            { 'title': 'Intarsia', 'slug': 'Instarsia Knit Beanie' },
            { 'title': 'Cable', 'slug': 'Cable Beanie' },
            { 'title': 'Chunky', 'slug': 'Chunky Knit Beanie' }
          ]
        }
      ]
    };

    Object.keys(this.filters.hats).forEach(key => {
      this.filterArray = this.filters.hats[key];
      Object.keys(this.filterArray.data).forEach(ele => {
        this.filterItems.push(this.filterArray.data[ele].slug);
      });
    });
    Object.keys(this.filters.beanies).forEach(key => {
      this.filterArray = this.filters.beanies[key];
      Object.keys(this.filterArray.data).forEach(ele => {
        this.filterItems.push(this.filterArray.data[ele].slug);
      });
    });
    
    this.dataKey = [];
    this.params = new HttpParams();
    this.paramskey = [];
    Object.keys(this.route.snapshot.queryParams).forEach(key => {
      this.params = this.params.append(key, this.route.snapshot.queryParams[key]);
      if (this.route.snapshot.queryParams[key] && (this.filterItems.includes(this.route.snapshot.queryParams[key]))) {
        this.paramskey[key] = this.route.snapshot.queryParams[key];
        this.dataKey.push(this.route.snapshot.queryParams[key]);
      }
      if (this.route.snapshot.queryParams[key]) {
        this.routeCount = 1;
      }
    });
    this.filterCount = Object.keys(this.dataKey).length;
    this.getPortfolioData(this.offsetValue);
  }

  changeImage(item) {
    document.querySelector('.product-image').setAttribute('src', item.preview_thumbnail);
    document.querySelector('.product-image-modal img').setAttribute('src', item.preview_thumbnail);
  }

  filterItemType(item_type) {
    this.resutItems = [];
    this.offsetValue = 0;
    this.params = this.params.delete('item_type');
    this.params = this.params.append('item_type', item_type);

    this.params = new HttpParams();
    this.params = this.params.delete('item_type');
    this.params = this.params.append('item_type', item_type);
    this.dataKey = [];
    this.paramskey = [];
    this.filterCount = 0;
    this.router.navigate([], { queryParams: this.paramskey });

    if(item_type === 'Hat') {
      this.filterVisibleHats = true;
      this.filterVisibleBeanies = false;
      this.filterVisible = true;
    } else  if(item_type === 'Beanie') {
      this.filterVisibleBeanies = true;
      this.filterVisibleHats = false;
      this.filterVisible = true;
    } else {
      this.filterVisibleBeanies = false;
      this.filterVisibleHats = false;
    }
    this.getPortfolioData(this.offsetValue);
  }

  getPortfolioData(offsetValue?) {
    this.params = this.params.delete('offset');
    this.params = this.params.append('offset', offsetValue );
    this.params = this.params.delete('limit');
    this.params = this.params.append('limit', '9' );
    this.loading = true;
    this.programService.getPortfolio(this.apiUrl, this.params).subscribe(
      (data) => {
        this.loading = false;
        this.result = data;
        data.results.forEach(element => {
          this.resutItems.push(element);
        });
        this.count = data.count;
      },
      (error: any) => {
        this.loading = false;
        this.toastr.error(error);
      }
    );
  }

  downloadPDF(file) {
    window.open(file);
  }

  imgLoad() {
    this.imgLoaded = true;
  }

  changeView(val) {
    this.viewCount = val;
  }
  // popup image
  getImageDetails(ImageData) {
    this.imageAllData = ImageData;
    if(ImageData.item_type === 'Hat') {
      this.imageSingleDataA = ImageData.images[0].file;
      this.imageSingleDataB =  ImageData.images[1].file;
    } else {
      this.imageSingleDataA = ImageData.images[0].file;
    }
  }

  filterView(e) {
    this.filterVisible = !this.filterVisible;
    this.filterVisibleHats = true;
    this.filterVisibleBeanies = false;
    this.params = this.params.delete('item_type');
    this.params = this.params.append('item_type', 'Hat');
    this.resutItems = [];
    this.offsetValue = 0;
    this.getPortfolioData(this.offsetValue);
  } 

  filterData(dataValue, spec) {
    this.dataKey = [];
    this.routeCount = 1;
    this.resutItems = [];
    this.offsetValue = 0;
    Object.keys(this.route.snapshot.queryParams).forEach(key => {
      if (this.route.snapshot.queryParams[key] && (!this.filterItems.includes(this.route.snapshot.queryParams[key]))) {
        this.params = this.params.delete(key);
      }
    });
    if (spec) {
      if (this.params.get(spec) === dataValue) {
        delete this.paramskey[spec];
        this.params = this.params.delete(spec);
      } else {
        this.paramskey[spec] = dataValue;
        this.params = this.params.delete(spec);
        this.params = this.params.append(spec, dataValue);
      }
    }
    Object.keys(this.paramskey).forEach(ele => {
      this.dataKey.push(this.paramskey[ele]);
    });
    
    this.router.navigate([], { queryParams: this.paramskey });
    this.filterCount = Object.keys(this.dataKey).length;
    this.getPortfolioData(this.offsetValue);
  }

  onScroll(e) {
    this.offsetValue = this.offsetValue + 9;
    this.getPortfolioData(this.offsetValue);
  }
}