import { FormControl, FormGroup, AbstractControl,  ValidatorFn } from '@angular/forms';

export class CustomValidator {

  static email(control: FormControl) {

    // tslint:disable-next-line:max-line-length
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return EMAIL_REGEXP.test(control.value) ? null : {
      validateEmail: {
        valid: false
      }
    };
  }

  static whitespace(control: FormControl) {

    const pattern = /^[^\s]+(\s+[^\s]+)*$/;

    return pattern.test(control.value) ? null : {
      noWhitespace: {
        valid: false
      }
    };
  }

  static number(control: FormControl) {

    const pattern = /^[0-9]+(\.[0-9]*){0,1}$/g;

    return pattern.test(control.value) ? null : {
      validateNumber: {
        valid: false
      }
    };
  }

  static password(control: FormControl) {

    const pattern = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#\$%\^&\*])[A-Za-z\d]{4,20}/;
    return pattern.test(control.value) ? null : {
      invalidPassword: {
        valid: false
      }
    };
  }

  static minLengthArray(min: number) {
    return (c: AbstractControl): {[key: string]: any} => {
      if (c.value.length >= min) {
        return null;
      }
      return { 'minLengthArray': {valid: false }};
    };
  }


  static confirmPassword(formGP: FormGroup) {
    const password = formGP.controls.password.value;
    const repeatPassword = formGP.controls.confirmPassword.value;

    if (repeatPassword.length <= 0) {
      return null;
    }

    if (repeatPassword !== password) {
      return {
        doesMatchPassword: true
      };
    }

    return null;
  }

  static noWhitespace(control: FormControl) {

    const pattern = /\s+/g;

    return (!pattern.test(control.value)) ? null : {
      noWhitespace: {
        valid: false
      }
    };
  }

  static noWhitespaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : {
        required: {
          valid: false
        }
      };
  }

}
