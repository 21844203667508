import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-compare-programs',
  templateUrl: './compare-programs.component.html',
  styleUrls: ['./compare-programs.component.scss']
})
export class CompareProgramsComponent implements OnInit {
  programDetails: any = [];
  textContent: any;
  pdfView: boolean;
  constructor() { }

  ngOnInit() {
    this.programDetails = {
      'header':
        [
          {
            'title': 'Blank Stock',
            'subtitle': 'Program',
            'slug': 'blank-stock-program'
            },
          {
            'title': 'Made In USA',
            'subtitle': 'Program',
            'slug': 'made-in-usa-program'
          },
          {
            'title': 'Import',
            'subtitle': 'Programs',
            'slug': 'import-programs'
          }
        ],
      'basics':
        [

          {
            'content':
            [
              { 'data': 'Blank hats embellished with your choice of embroidery or printing. ' },
              { 'data': 'Fastest lead time with lowest minimum.' },
              { 'data': 'Perfect for basic hats  or orders requiring a fast turn around.' }
            ]
          },
          {
            'content':
            [
              { 'data': 'Cut & sewn hats made to order.' },
              { 'data': 'Choose from our stocked fabric options along with embroidery or printing.' },
              { 'data': 'You can also provide your own fabrics and trims.' }
            ]
          },
          {
            'title': 'Low Minimum',
            'subtitle': '(24 pcs)',
            'content':
            [
              { 'data': 'Cut & sewn hats made to order.' },
              { 'data': 'Lowest minimum with many fabric and embellishment options to choose from.' }
            ]
          },
          {
            'title': 'Volume',
            'subtitle': '(144 pcs)',
            'content':
            [
              { 'data': 'Cut & sewn hats made to order. ' },
              { 'data': 'Best pricing with the most customizing options.' }
            ]
          }




        ],
      'list':
        [
          {
            'title': 'Mininum Order',
            'data': [
              {
                'blankStock': '24 pcs',
                'madeInUsa': '60 pcs',
                'lowMinimum': '24 pcs',
                'volume': '144 pcs'
              }
            ]
          },
          {
            'title': 'Sample Leadtime*',
            'data': [
              {
                'blankStock': '10 days',
                'madeInUsa': '15 days',
                'lowMinimum': 'No sampling',
                'volume': '30 days'
              }
            ]
          },
          {
            'title': 'Production Leadtime*',
            'data': [
              {
                'blankStock': '15 days',
                'madeInUsa': '45 days',
                'lowMinimum': '45 days',
                'volume': '50-75 days**'
              }
            ]
          },
          {
            'title': 'Sample Fees',
            'data': [
              {
                'blankStock': '$25',
                'madeInUsa': '$40',
                'lowMinimum': 'Not applicable',
                'volume': '$25'
              }
            ]
          },
          {
            'title': 'Setup Fees',
            'setupFee': [
              {
                'blankStock': [
                  {
                    'head': 'Embroidery Digitizing:',
                    'data': '$7.50 per 1k stitches'
                  }
                ],
                'madeInUsa': [
                  {
                    'head': 'Embroidery Digitizing:',
                    'data': '$7.50 per 1k stitches'
                  }
                ],
                'lowMinimum': [
                  {
                    'head': 'No setup fees'
                  }
                ],
                'volume': [
                  {
                    'head': 'No setup fees'
                  }
                ]
              }
            ]
          },
          {
            'title': 'Average Production Pricing',
            'itemPrice': [
              {
                'blankStock': [
                  {
                    'caps': '$5.50 to $12.50',
                    'beanies': '$4.90 to $7.90'
                  }
                ],
                'madeInUsa': [
                  {
                    'caps': '$11.00 to $15.00',
                    'beanies': 'Not Available'
                  }
                ],
                'lowMinimum': [
                  {
                    'caps': '$9.50 to $13.50',
                    'beanies': '$6.70 to $9.00'
                  }
                ],
                'volume': [
                  {
                    'caps': '$5.00 to $8.00',
                    'beanies': '$3.50 to $5.90'
                  }
                ]
              }
            ]
          }
        ]
    };
  }
  downloadPDF() {
    window.open('assets/compare-programs.pdf');
  }
}
