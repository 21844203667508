import { Routes} from '@angular/router';

import { ContentLayoutComponent, UnauthorizedComponent, NotfoundComponent } from './layout';
import { CompareProgramsComponent, PortfolioComponent, AboutComponent, ContactComponent, HomeComponent } from './modules';

export const AppRoutes: Routes = [
  {
    path: '',
    component: ContentLayoutComponent,
    children: [
        {
          path: '',
          pathMatch: 'full',
          component: HomeComponent
        },
        {
            path: 'programs',
            loadChildren: 'app/modules/programs/programs.module#ProgramsModule'
        },
        {
            path: 'compare-programs',
            component: CompareProgramsComponent,
            
        },
        {
            path: 'portfolio',
            component: PortfolioComponent,
            data: {
                header: 'portfolio'
            }
        },
        {
            path: 'about',
            component: AboutComponent,
            data: {
                header: 'about'
            }
        },
        {
            path: 'contact',
            component: ContactComponent,
            data: {
                header: 'Contact Us.'
            }
        }
    ]
  },
  {
    path: '**',
    redirectTo: '404',
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: '404',
    component: NotfoundComponent,
  },
];
// @NgModule({
//   imports: [RouterModule.forRoot(routes)],
//   exports: [RouterModule]
// })
// export class AppRoutingModule { }

