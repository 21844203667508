import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-accordion',
  templateUrl: './product-accordion.component.html',
  styleUrls: ['./product-accordion.component.scss']
})
export class ProductAccordionComponent implements OnInit {
  productDetails: any;
  tabActive: any;

  constructor(private route: ActivatedRoute, public router: Router) {
    this.tabActive = this.route.snapshot.data['id'];
    sessionStorage.setItem('tabActive', this.tabActive);
  }

  ngOnInit() {
    this.productDetails = JSON.parse(sessionStorage.getItem('productDetails'));
  }

}
