import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss']
})
export class ContentHeaderComponent {
  textContent: any;
  pdfView: boolean;
  headerItem: string;
  listUrl: any;
  constructor(public router: Router, private route: ActivatedRoute, private titleService: Title) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe(event => {  // note, we don't use event
        this.headerItem = '';
        let currentRoute = this.route.root,
          url = '';
        do {
          const childrenRoutes = currentRoute.children;
          currentRoute = null;
          childrenRoutes.forEach(routes => {
            if (routes.outlet === 'primary') {
              const routeSnapshot = routes.snapshot;
              url += '/' + routeSnapshot.url.map(segment => segment.path).join('/');
              if (routes.snapshot.data.header !== undefined && routes.snapshot.data.header !== '') {
                this.headerItem = routes.snapshot.data.header;
              }
              currentRoute = routes;
            }
          });
        } while (currentRoute);
      });
  }

 
  downloadPDF() {
    window.open('assets/compare-programs.pdf');
  }

  // block header
  public checkRoute() {
    this.listUrl = [
      'contact',
      'about',
    ];
    return this.router.url.split('/')
      .some(url => this.listUrl.includes(url));
  }

}
