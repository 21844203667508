
import {catchError} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService, ToasterService} from './services';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private inj: Injector,
    private router: Router
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authService = this.inj.get(AuthService); // authservice is an angular service
    const jwtToken = authService.getToken();

    // if (req.url.indexOf(environment.rocketShipServerUrl) === -1) {
    //   if (jwtToken && !req.headers.has('Authorization')) {
    //     req = req.clone({
    //       headers: req.headers.set('Authorization', 'Bearer ' + jwtToken)
    //     });
    //   }
    // }
    if (jwtToken && !req.headers.has('Authorization')) {
      req = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + jwtToken)
      });
    }

    // if (!req.headers.has('Content-Type')) {
    //   req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    // }
    return next.handle(req).pipe(catchError((error) => {
      if (error instanceof HttpErrorResponse) {
        switch ((<HttpErrorResponse>error).status) {
          case 400:
            return this.handle400Error(error);
          case 401:
            authService.collectFailedRequest(req);
            return this.handle401Error(error);
          case 403:
            return this.handle403Error(error);
          case 404:
            return this.handle404Error(error, req.url);
          default:
            return observableThrowError(error);
        }
      } else {
          return observableThrowError(error);
      }
    }));

  }

  handle400Error(error) {
    if (error && error.status === 400 && error.error && error.error.error === 'invalid_grant') {
      // If we get a 400 and the error message is 'invalid_grant', the token is no longer valid so logout.
      return this.logoutUser();
    }

    return observableThrowError(error);
  }

  handle403Error(error) {
    if (error && error.status === 403 && error.error && error.error.detail) {
      const errorMsg = error.error.detail;
      if (errorMsg === 'Signature has expired.') {
        return this.logoutUser();
      } else {
        const toastr = this.inj.get(ToasterService);
        toastr.error(errorMsg);
      }
    }

    return observableThrowError(error);
  }

  handle401Error(error) {
    if (error && error.status === 401 && error.error && error.error.detail) {
      const errorMsg = error.error.detail;
      if (errorMsg === 'Authentication credentials were not provided.') {
        return this.logoutUser();
      } else {
        const toastr = this.inj.get(ToasterService);
        toastr.error(errorMsg);
      }
    }

    return observableThrowError(error);
  }

  handle404Error(error, url) {
    // const toastr = this.inj.get(ToasterService);
    // toastr.error('404 not found ' + url);
    this.router.navigate(['/404']);
    return observableThrowError('');
  }

  logoutUser() {
    const authService = this.inj.get(AuthService); // authservice is an angular service

    // Remove sessions and route to the login page
    authService.purgeAuth();
    this.router.navigate(['/login']);
    return observableThrowError('');
  }
}
