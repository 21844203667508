import { Component, OnInit, ElementRef } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToasterService, SharedService, CustomValidator, phoneNumberMask } from '@app/shared';
import { ProgramService } from '@app/modules/shared/services/program.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactsForm: FormGroup;
  invalidForm: boolean;
  formSubmitted: boolean;
  error: any;
  apiUrl = 'contact/';
  mask = phoneNumberMask;
  interestedProgram = [
    'Blank Stock Program',
    'Made in USA Program',
    'Low Minimum Program',
    'Volume Program',
    'Not sure',
    'More than one program'
  ];

  constructor(
    private fb: FormBuilder,
    private el: ElementRef,
    private toastr: ToasterService,
    private programService: ProgramService,
    private sharedService: SharedService
  ) {
    this.contactsForm = this.fb.group({
      'name': ['', Validators.compose([Validators.required, CustomValidator.noWhitespaceValidator])],
      'company_name': ['', Validators.compose([Validators.required, CustomValidator.noWhitespaceValidator])],
      'email': ['', Validators.compose([Validators.required, Validators.email, CustomValidator.email])],
      'phone': [''],
      'website': [''],
      'company_instagram': [''],
      'no_years_in_business': [''],
      'interested_program': [''],
      'how_hear_about_us': [''],
      'produced_before': [''],
      'message': [''],
    });
  }

  ngOnInit() {
  }


  onSubmit(validPost) {
    if (this.contactsForm.valid) {
      this.invalidForm = false;
      this.formSubmitted = true;
      this.programService.saveCustomerContacts(this.apiUrl, validPost).subscribe(
        (data) => {
          this.toastr.success('Thank You for contacting us! We will be in touch with you shortly.');
          this.formSubmitted = false;
          this.contactsForm.reset();
          this.contactsForm.get('interested_program').setValue('');
          window.scrollTo(0, 0);
        },
        (error: any) => {
          this.formSubmitted = false;
          this.error = this.sharedService.handleError(error);
          this.toastr.error('Unknown error. Please try later');
        }
      );
    } else {
      this.invalidForm = true;
      const target = this.el.nativeElement.querySelector('.ng-invalid:not(form)');
      if (target) {
        target.focus();
      }
    }
  }

}
