import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TextMaskModule } from 'angular2-text-mask';

// third-party module imports
import { NgxWebstorageModule } from 'ngx-webstorage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from 'ng-pick-datetime';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';

export const MY_NATIVE_FORMATS = {
  fullPickerInput: {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false},
  datePickerInput: {year: 'numeric', month: 'numeric', day: 'numeric'},
  timePickerInput: {hour: 'numeric', minute: 'numeric'},
  monthYearLabel: {year: 'numeric', month: 'short'},
  dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
  monthYearA11yLabel: {year: 'numeric', month: 'long'},
};

// custom module imports
import {
  SpinnerModule,
  TableModule,
  AutocompleteModule,
} from './modules';

// custom component imports
import {
  ModalPopupComponent,
  IconComponent,
  LoaderComponent,
  SpinnerComponent,
  ValidationErrorsComponent,
  AccordionComponent,
  ServerValidationErrorComponent,
} from './components';

// custom service imports
import {
  ToasterService,
  StorageService,
  AuthService,
  ApiService,
  SharedService,
} from './services';

import {
  CurrencyFormat,
  CapitalizeFirstPipe,
  FormatPhoneNumberPipe
} from './pipes';

import { AccessChecker, IsGrantedDirective } from './security';
import { AuthInterceptor } from './interceptor';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,

    // third-party modules
    NgxWebstorageModule.forRoot({ prefix: 'reach', separator: '|'}),
    NgbModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,

    // custom modules
    SpinnerModule,
    TableModule,
    AutocompleteModule,
  ],
  declarations: [
    SpinnerComponent,
    LoaderComponent,
    IconComponent,
    ModalPopupComponent,
    ValidationErrorsComponent,
    ServerValidationErrorComponent,
    AccordionComponent,
    IsGrantedDirective,
    CurrencyFormat,
    CapitalizeFirstPipe,
    FormatPhoneNumberPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    TextMaskModule,

    // third-party modules
    NgbModule,
    OwlDateTimeModule,

    // custom modules
    SpinnerModule,
    TableModule,
    AutocompleteModule,

    // custom components
    SpinnerComponent,
    LoaderComponent,
    IconComponent,
    ModalPopupComponent,
    ValidationErrorsComponent,
    ServerValidationErrorComponent,
    AccordionComponent,
    IsGrantedDirective,

    // custom pipes
    CurrencyFormat,
    CapitalizeFirstPipe,
    FormatPhoneNumberPipe
  ],
  providers: [
    ToasterService,
    StorageService,
    SharedService,
    AccessChecker,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MY_NATIVE_FORMATS
    }
  ],
})
export class SharedModule { }
