
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { environment } from 'environments/environment';
import { HttpParams } from '@angular/common/http';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnChanges {
  @Input() columns: any;
  @Input () page: number;
  @Input() config: any = {
    paging: true,
    search: true,
    sorting: false,
    className: ['table-bordered table-hover'],
    responsive: true,
    limit: true,
    additionalFilters: false,
    defaultRowCount: '15'
  };
  @Input() data: any;
  @Input() totalResults: any;
  @Input() deleteRow: boolean;
  @Input() params: HttpParams = new HttpParams();
  @Input() navigatePage: string;

  /** Show loader in the container based on this value */
  @Input() loading: boolean;

  @Output() tableChanged: EventEmitter<any> = new EventEmitter();
  @Output() paramsChanged: EventEmitter<any> = new EventEmitter();


  rows = ['15', '30', '50', '100'];
  results = [];
  term = new FormControl();
  tableData = {
    'search': '',
    'rows': environment.tableRowLimit,
    'sort': '',
    'page': 1,
    'offset': 0,
    'filter_type': '',
  };

  start = 1;
  currentPage = 1;

  response: any = {
    tableData: this.tableData,
    start: 1,
    params: this.params
  }

  constructor() {
    this.term.valueChanges.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(
      (data) => {
        this.onChangeTable(data, 'search');
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue) {
      this.results = changes.data.currentValue;
    }

    if (this.page && this.page !== this.tableData.page) {
      this.tableData.page = this.page;
    }
    if (changes.deleteRow && this.deleteRow) {
      if (this.data && this.data.length === 1 && this.tableData.page !== 1) {
        const page = this.tableData.page - 1;
        this.onChangeTable(page, 'paging');
      } else {
        this.onChangeTable(this.tableData.page, 'paging');
      }
    }

    if (changes.navigatePage && this.navigatePage) {
      const rows = parseInt(this.tableData.rows, 10);
      if (this.navigatePage === 'first') {
        this.onChangeTable(1, 'paging');
      } else {
        const lastPage = Math.ceil(this.totalResults / rows);
        const newLastPage = Math.ceil((this.totalResults + 1) / rows);
        if (newLastPage > lastPage) {
          this.totalResults += 1;
          this.onChangeTable(newLastPage, 'paging');
        } else {
          this.onChangeTable(lastPage, 'paging');
        }
      }
    }

    if (this.params.has('search')) {
      this.term.setValue(this.params.get('search'));
    }
  }

  onChangeTable (data, type) {
    this.tableData.filter_type = type;

    if (type === 'sort' && data && data.sort !== false) {
      switch (data.sort) {
        case 'asc':
          data.sort = 'desc';
          break;
        case 'desc':
          data.sort = true;
          break;
        default:
          data.sort = 'asc';
          break;
      }
      this.tableChanged.emit(this.tableData);
      let sortParam = '';
      this.columns.forEach(function(col, key) {
        if (col.sort) {
          if (col.sort === 'desc') {
            sortParam = sortParam !== '' ? sortParam + ',' + col.name  : '-' + col.name;
          } else if (col.sort === 'asc') {
            sortParam = sortParam !== '' ? sortParam + ',' + col.name  : col.name;
          }
        }
      });
      this.params = this.params.delete('ordering');
      if (sortParam !== '') {
        this.params = this.params.append('ordering', sortParam);
      }
    } else if (type === 'paging') {
      this.tableData.page = data;
      this.tableChanged.emit(this.tableData);
      const offset = ((this.tableData.page - 1) * parseInt(this.tableData.rows, 10));
      this.start = offset + 1;
      this.tableData.offset = offset;
      this.params = this.params.delete('offset');
      this.params = this.params.append('offset', offset.toString());
    } else if (type === 'row') {
      this.tableData.rows = data.target.value;
      this.tableChanged.emit(this.tableData);
      const offset =  ((this.tableData.page - 1) * parseInt(this.tableData.rows, 10));
      this.start = offset + 1;
      this.params = this.params.delete('offset');
      this.params = this.params.append('offset', offset.toString());
      this.params = this.params.delete('limit');
      this.params = this.params.append('limit', this.tableData.rows);
    } else if (type === 'search') {
      this.tableData.search = data;
      this.tableData.page = 1;
      if (this.params && this.params.has('offset')) {
        this.params = this.params.delete('offset');
      }
      this.tableChanged.emit(this.tableData);
      this.params = this.params.delete('search');
      if (this.tableData.search !== '') {
        this.params = this.params.append('search', this.tableData.search);
      }
    }

    this.emitResponse();
  }

  emitResponse() {
    this.response = {
      tableData: this.tableData,
      start: this.start,
      params: this.params
    }

    this.paramsChanged.emit(this.response);
  }
}
