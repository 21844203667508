import { Component, OnInit, ViewChild, Inject, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
declare var $: any;
declare var AOS: any;
declare var jQuery: any;
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {

  @ViewChild('horizontal') horizontal;
  @ViewChild('sliderHeader') sliderHeader;

  slides = [
    { img: 'assets/images/about-3.jpg' },
    { img: 'assets/images/about-slider4.jpg' },
    { img: 'assets/images/about-slider3.jpg' },
    { img: 'assets/images/about-slider2.jpg' },
    { img: 'assets/images/about-slider1.jpg' }
  ];
  slidesContent = [
    // tslint:disable-next-line: max-line-length
    { text: 'The caliber of brands we produce for should be a testament of our dedication to quality and craftsmanship. We have a wide range of options and are constantly striving to push headwear as not just an accessory, but a staple category. Using only the highest standards in components and materials, our staff is committed to bring your designs to life with a focus on premium quality and attention to detail.' },
    { header: 'On-Trend Fits.', text: 'We work with leading brands across each market to develop our fits, so you can be sure each fit is market appropriate and on-trend.' },
    // tslint:disable-next-line: max-line-length
    { header: 'Cut to Order.', text: 'For our cut & sew programs, fabric isn’t pulled from the roll until you place your order, allowing you to design a truly custom hat, from the color combinations and fabric choices to the stitching and eyelets.<br><br>Shown here are the cutting dies for our low profiile 6 panel snapback.' },
    { header: 'Fabrics.', text: 'From our closures to our brims and everything in between, we use only the highest grade components ensuring quality, second to none.' },
    { header: 'Components.', text: 'From our closures to our brims and everything in between, we use only the highest grade components ensuring quality, second to none.' }

  ];
  horizontalSlideConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '.slider-next-horizontal',
    nextArrow: false,
    touchMove: false,
    swipe: false,
    speed: 500,
    cssEase: 'ease-in'
  };
  verticalSlideConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    nextArrow: '.slider-next-vertical',
    prevArrow: false,
    touchMove: false,
    swipe: false,
    speed: 500,
    cssEase: 'ease-in'
  };
  verticalSlideHeadConfig = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    vertical: true,
    verticalSwiping: true,
    nextArrow: '.slider-next-header',
    prevArrow: false,
    touchMove: false,
    swipe: false,
    speed: 500,
    cssEase: 'ease-in'
  };

  constructor (
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
) { }

  ngOnInit() {

    if ($(window).width() >= 1280) {
      $('.parallax-move').parallaxContent({
        'shift': -45  ,
        'duration': 1,
        'gyroSensitivity': 30
      });
      // $('.parallax-move-content').parallaxContent({
      //   'shift': 0,
      //   'duration': 1,
      //   'gyroSensitivity': 30
      // });
    }
    AOS.init({
      once: true,
      duration: 3000,
      offset: 100,
      anchorPlacement: 'bottom-top'
    });
    this.renderer.addClass(this.document.body, 'about-body');
  }

  slideItem() {
    this.horizontal.nativeElement.click();
    this.sliderHeader.nativeElement.click();
    if ($(window).width() < 768) {
      $("html, body").animate({ scrollTop: ($('.about-slider-wrap').offset().top - 70 )}, 600);
    }
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(this.document.body, 'about-body');
}
}
